input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
input[type='phone'],
input[type='date'] {
    border: 1px solid color(primary);
    border-radius: 4px;
    padding: 0 11px;
    min-height: 28px;
    font-size: 12px;
    line-height: 20px;

    &:active, &:focus {
        border-color: color(third);
    }

    @include breakpoint-up(md) {
        font-size: 14px;
        line-height: 24px;
        min-height: 42px;
    }

    &.wpcf7-not-valid {
        border-color: color(error);
        color: color(error);
    }
}

textarea {
    max-width: 100%;
    border: 1px solid color(primary);
    border-radius: 4px;
    padding: 6px 11px;
    min-height: 147px;
    resize: none;
    max-width: 100%;
    font-size: 12px;
    line-height: 20px;

    &:active, &:focus {
        border-color: color(third);
    }

    @include breakpoint-up(md) {
        font-size: 14px;
        line-height: 24px;
    }
}

::placeholder { 
    color: color(primary);
    opacity: 1;
}

:-ms-input-placeholder { 
    color: color(primary);
}

::-ms-input-placeholder { 
    color: color(primary);
}

label {
    display: block;
    margin-bottom: 6px;

    @include breakpoint-up(md) {
        margin-bottom: 10px;
    }
}

.error-message {
    margin: .2em 0;
    color: color(error);
    @include font('small');
}

.gform_wrapper {
    .gravity-group {
        border: none;
        width: 100%;
        font-size: 12px;
        line-height: 20px;
        padding: 0;
        margin: 0;

        @include breakpoint-up(md) {
            font-size: 14px;
            line-height: 24px;
        }

        &.gfield_error {
            input, textarea {
                border-color: color(error);
            }
            
        }

        
    }

    input[type='submit'] {
        display: inline-block;
        font-size: 12px;
        line-height: 20px;
        padding: 3px 17px;
        border: 1px solid color(third);
        border-radius: 47px;
        transition: all 0.3s ease-in-out;
        background: color(third);
        color: color(white);
        text-decoration: none;
        cursor: pointer;

        &:active,
        &:focus,
        &:hover {
            background: color(white);
            color: color(third);
        }

        @include breakpoint-up(md) {
            padding: 5px 24px;
            font-size: 14px;
            line-height: 24px;
        }
    }
}

.ssl-info {
    display: flex;
    align-items: baseline;

    span {
        font-size: 9px;
        line-height: 11px;
        margin-left: 5px;
    }
}