.select2-container {

    .select2-selection--single {
        border: 1px solid color(primary);
        border-radius: 4px;
        min-height: 28px;
        color: color(black);
    
        @include breakpoint-up(md) {
            min-height: 42px;
        }
    }

    &--default {
        .select2-selection--single .select2-selection__rendered {
            width: 100%;
            color: color(black);
            height: 100%;
            font-size: 12px;
            line-height: 20px;
            display: flex;
            align-items: center;
            padding-left: 11px;
    
            @include breakpoint-up(md) {
                font-size: 14px;
                line-height: 24px;
                padding-left: 14px;
            }
        }

        .select2-selection--single .select2-selection__arrow {
            height: 100%;
            width: 36px;
            background-image: url('svg/chevron-down.svg');
            background-size: 12px 6px;
            background-position: center;
            background-repeat: no-repeat;
            transition: 0.3s all ease-in-out;

            b {
                display: none;
            }
        }

        .select2-results {
            padding-bottom: 10px;
        }

        .select2-results__option {
            padding: 2px 11px;
            line-height: 20px;

            @include breakpoint-up(md) {
                line-height: 24px;
                padding: 2px 14px;
            }

            &--highlighted[aria-selected] {
                background-color: transparent;
                color: color(third);
            }

            &[aria-selected=true] {
                background-color: transparent;
                color: color(third);
            }
        }
    }

    &--open {
        .select2-selection--single {
            border-bottom: none;
        }

        .select2-selection--single .select2-selection__arrow {
            transform: rotate(180deg);
        }
    }
} 
