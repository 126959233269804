.stage {
    margin: 36px 0 68px;

    @include breakpoint-up(md) {
        margin: 36px 0 78px;
    }

    &-nav {
        max-width: 432px;
        margin: 0 auto 50px;

        @include breakpoint-up(md) {
            margin-bottom: 73px;
        }

        &__list {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__item {
            text-align: center;
        }

        &__icon {
            margin: 0 auto 10px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: color(primary);

            &--success {
                background-color: #00E5B6;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    left: 7px;
                    top: 4px;
                    width: 4px;
                    height: 8px;
                    border: solid color(white);
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg); 
                }
            }
        }

        &__title {
            font-size: 9px;
            line-height: 20px;

            @include breakpoint-up(md) {
                font-size: 14px;
                line-height: 24px;
            }

            &--success {
                color: #00E5B6;
            }
        }
    }

    &-title {
        margin-bottom: 49px;

        @include breakpoint-up(md) {
            margin-bottom: 68px;
        }
    }

    &--fourth {

        @include breakpoint-up(md) {
            margin: 36px 0 131px;
        }

        &-content {
            max-width: 456px;
        }
    }

    &-subtitle {
        &--fourth {
            text-align: center;
        }
    }

    &-wrap {
        margin: 0 auto;

        &--fourth {
            max-width: 456px;
        }

        &__content {
            ol {
                margin: 20px 0;
                list-style: decimal;
                padding-left: 13px;
            }
    
            li {
                margin-bottom: 10px;
            }
    
            a {
                text-decoration: underline;
                transition: 0.3s all ease-in-out;
    
                &:hover {
                    opacity: 0.8;
                }
            }
    
            p {
                font-size: 12px;
                line-height: 20px;
                margin-bottom: 20px;
    
                @include breakpoint-up(md) {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
    }

    &-btns {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
    }
}