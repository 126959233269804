.featured-posts {
    margin-top: 65px;

    @include breakpoint-up(md) {
        margin-top: 85px;
    }

    &__title {
        text-align: center;
        margin-bottom: 27px;
        
        @include breakpoint-up(md) {
            margin-bottom: 38px;
        }
    }

    .c-article-card {
        margin-bottom: 65px;
    }
}