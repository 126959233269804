$colors: ( 
    background: #fff,
    text: #000,
    white: #fff,
    black: #000,
    primary: #BEBEBE,
    secondary: #6E6E6E,
    third: #694BFF,
    dark: #0A2328,
    light: #DEF4F9,
    error: #FF321A
);
