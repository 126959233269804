.static-txt {
    margin: 62px 0 68px;

    @include breakpoint-up(md) {
        margin: 83px 0 75px;
    }

    p {
        font-size: 12px;
        line-height: 20px;

        @include breakpoint-up(md) {
            font-size: 14px;
            line-height: 24px;
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 -15px;
    }

    &__col {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 15px;

        @include breakpoint-up(sm) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    &__box {

        @include breakpoint-up(sm) {
            max-width: 494px;
        }

        &--right {

            @include breakpoint-up(md) {
                margin-right: 0;
                margin-left: auto;
            }
        }
    }

    &__flex {
        margin-top: 24px;
        margin-bottom: 14px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include breakpoint-up(md) {
            margin-top: 28px;
        }

        .c-btn {
            margin-right: 18px;

            @include breakpoint-up(md) {
                margin-right: 27px;
            }
        }
    }

    &__desc {
        color: color(secondary);
        font-size: 9px;
        line-height: 11px;
    }
}