.contact-section {
    margin: 60px 0;

    @include breakpoint-up(md) {
        margin: 78px 0 92px;
    }

    &__input {
        max-width: 310px;
    }

    .gform_wrapper {
        .gform_fields {
            flex-wrap: wrap;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin: 0 -12px;

            @include breakpoint-up(md) {
                margin: 0 -25px;
            }

            .gravity-group {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0 12px;
                order: 1;

                @include breakpoint-up(md) {
                    flex: 0 0 360px;
                    max-width: 360px;
                    padding: 0 25px;
                }

                &.contact-form__email {
                    @include breakpoint-up(md) {
                        margin-top: -84px !important;
                    }
                }

            }

            fieldset {
                border: none;
                padding: 0;
                margin: 0;
            }
        }

        .gform_footer {
            margin: 30px 0;
            text-align: right;

            @include breakpoint-up(md) {
                margin-bottom: 0;
            }
        }
    }

    .wpcf7-spinner {
        display: none;
    }

    .wpcf7-response-output {
        margin-top: 0 !important;
        margin-bottom: 30px !important;
    }

    .wpcf7-not-valid-tip {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 9px;
        transform: translateY(-18px);
    }
}

.contact-form {
    &__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 -25px;
    }

    &__col {
        padding: 0 25px;
        flex: 0 0 100%;
        max-width: 100%;

        @include breakpoint-up(md) {
            flex: 0 0 360px;
            max-width: 360px;
            margin: 0;
        }

        &--first {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;

            @include breakpoint-up(md) {
                flex-direction: column;
                justify-content: space-between;
                margin-bottom: 0;
            }
        }

        &--full {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    &__group {

        &--half {
            @include breakpoint-down(md) {
                flex: 0 0 48%;
                max-width: 48%;
            }
        }

        > span {
            display: block;
            font-size: 0;
        }
    }

    &__input {
        width: 100%;
    }

    &__textarea {
        width: 100%;
        max-width: 100%;
        resize: none;
        height: 147px;

        @include breakpoint-up(md) {
            height: 160px;
        }
    }

    &__bottom {
        margin: 30px 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        p {
            margin: 0;
        }

        input {
            margin-left: 35px;
        }
    }
}

.contact-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 -25px;

    &__form {
        padding: 0 25px;
        flex: 0 0 100%;
        max-width: 100%;

        @include breakpoint-up(md) {
            flex: 0 0 770px;
            max-width: 770px;
        }
    }

    &__address {
        padding: 0 25px;
        flex: 0 0 100%;
        max-width: 100%;
        font-size: 12px;
        line-height: 20px;

        @include breakpoint-up(md) {
            font-size: 14px;
            line-height: 24px;
            flex: 0 0 360px;
            max-width: 360px;
        }
    }

    &__title {
        margin-bottom: 26px;

        @include breakpoint-up(md) {
            margin-bottom: 22px;
        }
    }
}
