html {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background: color(white);
    color: color(text);
    font-family: $font-default;  
    font-size: 12px;

    @include breakpoint-up(md) {
        font-size: 14px;
    }
}

.container {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding-right: 25px;
    padding-left: 25px;
    max-width: 1158px;
}

.row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -25px;

    @include breakpoint-up(xl) {
        justify-content: space-between;
    }
}

.col {
    padding: 0 25px;
}

.wp-block-separator {
    margin: 0 25px;
    border-color: color(primary);
    border-bottom: none;
}

.page-section {
    padding: 60px 0 20px;

    @include breakpoint-up(md) {
        padding: 78px 0 20px;
    }
}

.page-title {
    text-align: center;
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 52px;

    @include breakpoint-up(md) {
        margin-bottom: 73px;
        font-size: 48px;
        line-height: 54px;
    }
}