a {
    color: color(text);
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
        color: colors(primary);
    }
}


.c-list {
    list-style: none;

    &__item {
        margin-bottom: 14px;

        @include breakpoint-up(md) {
            margin-bottom: 16px;
        }
    }

    &__link {
        text-decoration: underline;
        font-size: 12px;
        line-height: 18px;
        transition: all 0.3s ease-in-out;

        @include breakpoint-up(md) {
            font-size: 14px;
            line-height: 20px;
        }

        &:hover {
            color: color(third);
        }
    }
}