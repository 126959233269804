h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-default;  
    margin: 0 0 1em;
    line-height: 1.4;
    font-weight: normal;
}

p {
    margin: 0 0 1em;
    line-height: 1.5;
}

