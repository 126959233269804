.form-modal {
    opacity: 0;
    visibility: hidden;
    transition: 0.3s all ease-in-out;
    width: calc(100% - 40px);
    max-width: 326px;
    position: fixed;
    top: 110px;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid color(primary);
    background-color: color(white);
    padding: 17px 24px 25px 22px;
    border-radius: 4px;
    &.active {
        opacity: 1;
        visibility: visible;
        z-index: 10;
    }
    @include breakpoint-up(md) {
        width: 310px;
        top: 20%;
        padding: 19px 21px 26px 20px;
    }
    &__close {
        cursor: pointer;
        border: none;
        position: absolute;
        top: 13px;
        right: 12px;
        background-color: transparent;
        @include breakpoint-up(md) {
            top: 19px;
            right: 15px;
        }
    }
    &__title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 14px;
        @include breakpoint-up(md) {
            font-size: 24px;
            line-height: 32px;
        }
    }
    &__group {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-bottom: 24px;
        @include breakpoint-up(micro) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        @include breakpoint-up(md) {
            margin-bottom: 16px;
        }
    }
    &__label,
    label {
        margin-bottom: 0;
        margin-right: 15px;
        font-size: 12px;
        line-height: 20px;
        @include breakpoint-up(md) {
            font-size: 14px;
            line-height: 24px;
            max-width: 70px;
        }
    }
    &__input,
    input,
    .ginput_container_select {
        width: 189px;
        min-width: 189px;
        @include breakpoint-up(md) {
            min-width: 175px;
            width: 175px;
        }
    }
    .select2-container {
        width: 100% !important;
    }
    .gfield_required {
        display: none;
    }
    .validation_message {
        flex: 0 0 100%;
        max-width: 100%;
        color: color(error);
        @include breakpoint-up(micro) {
            text-align: right;
        }
    }
    &__submit,
    input[type="submit"] {
        width: auto;
        min-width: inherit;
        @include breakpoint-up(md) {
            width: 100%;
            margin-top: 12px;
        }
    }
}