.popular-visas {
    margin: 62px 0 38px;

    @include breakpoint-up(md) {
        margin: 50px 0;
    }

    &__title {
        text-align: center;
        margin-bottom: 27px;

        @include breakpoint-up(md) {
            margin-bottom: 33px;
        }
    }

    &__col {
        flex: 0 0 100%;
        max-width: 100%;

        @include breakpoint-up(md) {
            flex: 0 0 360px;
            max-width: 360px;
        }
    }
}

.visa-card {
    background-color: color(white);
    padding: 12px 20px 7px;
    border-radius: 4px;
    border: 1px solid color(primary);
    margin-bottom: 24px;
    display: flex;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    @include breakpoint-up(md) {
        flex-direction: column;
        justify-content: space-between;
        padding: 29px 30px 21px 28px;
        margin-bottom: 32px;
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        @include breakpoint-up(md) {
            margin-bottom: 18px;
        }
    }

    &__flag {
        font-size: 0;
        margin-right: 18px;

        svg {
            width: 100%;
            height: 100%;
        }

        img {
            width: 36px;
            height: 24px;
        }
    }

    &__visa {
        display: none;
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;

        @include breakpoint-up(md) {
            display: inline-block;
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
    }

    &__title {
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 0;

        @include breakpoint-up(md) {
            font-size: 48px;
            line-height: 64px;
        }
    }

    &__arrow {
        flex: 0 0 50px;
        max-width: 50px;
        height: 18px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        svg {
            path {
                transition: all 0.3s ease-in-out;
                stroke: color(black);
            }
        }

        &::before {
            content: '';
            background-color: color(black);
            position: absolute;
            right: 0;
            top: 50%;
            width: 43px;
            height: 1px;
            transition: all 0.3s ease-in-out;
        }
    }

    &:hover {
        transform: scale(1.05,1.05);

        .visa-card__arrow {
            &::before {
                background-color: color(third);
                width: 100%;
            }

            svg {
                path {
                    stroke: color(third);
                }
            }
        }
    }
}