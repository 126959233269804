.p-footer {
    background-color: color(dark);
    color: color(white);
    padding: 68px 0 62px;

    &__row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 -10px;

        @include breakpoint-up(md) {
            &--cards {
                margin: 0 0 0 auto;
                max-width: 350px;
            }

            &--visa {
                flex-direction: column;
                align-items: flex-start;
                margin-right: 30px;
                margin: 0;
            }
        }
    }

    &__col {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 10px;

        @include breakpoint-up(md) {
            &--visa {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }

    &__container {
        @include breakpoint-up(md) {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
        }
    }
}

.footer-nav {
    @include breakpoint-up(md) {
        order: 2;
        max-width: 350px;
    }
}

.footer-menu {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    @include breakpoint-up(md) {
        margin: 0 -10px 0 0;
    }

    &__item {
        padding: 0 10px;
        flex: 0 0 50%;
        max-width: 50%;
    }

    &__link {
        color: color(white);
    }
}

.footer-visa {
    margin: 43px 0 66px;
    @include breakpoint-up(md) {
        order: 1;
        flex-grow: 1;
        margin: 0 -10px 0;
    }

    &__title {
        margin-bottom: 0;

        @include breakpoint-up(md) {
            margin-bottom: 24px;
        }
    }
}

.footer-cards {
    @include breakpoint-up(md) {
        order: 3;
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 51px;
    }

    &__copy {
        font-size: 9px;
        line-height: 11px;
        margin-bottom: 0;
    }
}