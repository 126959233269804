.article-baner {
    width: 100%;
    height: 352px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    color: color(white);

    @include breakpoint-up(md) {
        height: 600px;
    }

    &__box {
        text-align: center;
        max-width: 714px;
    }

    &__title {
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 18px;

        @include breakpoint-up(md) {
            font-size: 48px;
            line-height: 54px;
            margin-bottom: 24px;
        }
    }

    &__date {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 0;

        @include breakpoint-up(md) {
            font-size: 14px;
            line-height: 24px;
        }
    }

    &__author {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 0;

        @include breakpoint-up(md) {
            font-size: 14px;
            line-height: 24px;
        }

        a {
            font-size: 14px;
            line-height: 24px;
            color: color(white);
            text-decoration: underline;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: color(third);
            }
        }
    }

    &__arrow {
        display: none;
        margin-top: 8.5%;
        border: none;
        background-color: transparent;
        cursor: pointer;

        @include breakpoint-up(md) {
            display: inline-block;
        }
    }
}

.article-content {
    padding: 65px 0;

    @include breakpoint-up(md) {
        padding: 82px 0;
    }

    figcaption {
        text-align: center;
        margin-bottom: 0;
    }

    p, ul, ol, dd, h1, h2, h3, h4 ,h5, h6 {
        max-width: 652px;
        margin-left: auto;
        margin-right: auto;
    }

    p {
        margin: 0 auto 20px;
    }

    .wp-block-image {
        margin: 60px 0;

        @include breakpoint-up(md) {
            margin: 80px 0;
        }

        img {
            height: auto;
        }
    }

    h1, h2 {

        font-size: 36px;
        line-height: 42px;
        margin: 53px auto 57px;

        @include breakpoint-up(md) {
            font-size: 48px;
            line-height: 54px;
            margin-top: 60px;
            margin-bottom: 20px;
        }
    }

    h3, h4, h5, h6 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 20px;

        @include breakpoint-up(md) {
            font-size: 24px;
            line-height: 32px;
        }
    }
}

.article-section {
    margin: 30px 0 70px;

    @include breakpoint-up(md) {
        margin: 90px 0;
    }
}

.article-col {
    flex: 0 0 100%;
    max-width: 100%;

    @include breakpoint-up(xs) {
        flex: 0 0 50%;
        max-width: 50%;
    }

    @include breakpoint-up(md) {
        flex: 0 0 360px;
        max-width: 360px;
    }
}

.c-article-card {
    margin: 0 auto 30px;

    @include breakpoint-up(md) {
        margin: 0 0 75px;
    }

    &__thumb {
        width: 100%;
        height: 0;
        padding-bottom: 71%;
        border-radius: 4px;
        overflow: hidden;
        backface-visibility: hidden;
        position: relative;
    }

    &__img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.3s ease-in-out;
    }

    &__date {
        color: color(secondary);
        display: block;
        margin: 15px 0 9px;
        font-size: 9px;
    }

    &__title {
        font-size: 12px;
        line-height: 20px;
        margin: 0;

        @include breakpoint-up(md) {
            font-size: 14px;
            line-height: 24px;
        }
    }

    &:hover {
        .c-article-card__img {
            transform: scale(1.1,1.1);
        }

        .c-article-card__arrow {
            &::before {
                background-color: color(third);
                width: 82px;
            }

            svg {
                path {
                    stroke: color(third);
                }
            }
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__arrow {
        flex: 0 0 82px;
        max-width: 82px;
        height: 18px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        svg {
            path {
                transition: all 0.3s ease-in-out;
            }
        }

        &::before {
            content: '';
            background-color: color(black);
            position: absolute;
            right: 0;
            top: 50%;
            width: 43px;
            height: 1px;
            transition: all 0.3s ease-in-out;
        }
    }
}