.c-form {
    position: relative;
    
    margin: 2em auto;
}

.c-form__group {
    position: relative;
    margin: 3em 0 2em;

    label {
        @include font('small');
    }
}

.c-form__submit {
    margin: 2em 0;
}
