.faq-block {
    margin: 62px 0 68px;

    @include breakpoint-up(md) {
        margin: 81px 0 84px;
    }

    &__title {
        text-align: center;
        margin-bottom: 27px;

        @include breakpoint-up(md) {
            margin-bottom: 33px;
        }
    }

    &__item {
        border-bottom: 1px solid color(primary);
        margin-bottom: 33px;

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;

            @include breakpoint-up(sm) {
                margin-bottom: 33px;
            }
        }
    }

    &__row {
        flex-wrap: wrap;
        display: flex;
        margin: 0 -15px;
        align-items: flex-start;
    }

    &__col {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 15px;

        @include breakpoint-up(sm) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    &__header {
        display: block;
        font-size: 9px;
        line-height: 11px;
        margin-bottom: 21px;
        color: color(secondary);
    }

    &__content {
        line-height: 20px;
        margin-bottom: 27px;

        @include breakpoint-up(md) {
            line-height: 24px;
            margin-bottom: 35px;
            max-width: 494px;

            &--right {
                margin-right: 0;
                margin-left: auto;
            }
        }
    }
}