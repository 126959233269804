.top-baner {
    padding: 60px 0 65px;
    margin: 0 25px;
    border-bottom: 1px solid color(primary);
    min-height: 350px;
    background-size: 0;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint-up(md) {
        min-height: 566px;
        background-size: 980px;
        background-position: 70%;
    }

    @include breakpoint-up(xl) {
        background-position: 40%;
        background-size: 1150px;
    }


    &__box {
        text-align: center;
        max-width: 1140px;
    }

    &__title {
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 9px;
        font-weight: normal;

        @include breakpoint-up(md) {
            font-size: 48px;
            line-height: 54px;
            margin-bottom: 23px;
        }
    }

    &__subtitle {
        font-size: 19px;
        line-height: 32px;

        @include breakpoint-up(xs) {
            font-size: 20px;
        }

        @include breakpoint-up(md) {
            font-size: 24px;
        }
    }

    &__btn {
        margin: 28px 0;

        @include breakpoint-up(md) {
            margin: 41px 0 13px;
        }
    }

    &__desc {
        font-size: 9px;
        line-height: 11px;
        color: color(secondary);
    }

    &--alt {
        min-height: 382px;
        margin: 0;
        padding-left: 25px;
        padding-right: 25px;
        background-size: contain;
        background-position: center;

        @include breakpoint-up(md) {
            min-height: 600px;
            background-size: 860px;
            background-position: 70% 70%;
        }

        @include breakpoint-up(lg) {
            background-position: 190% 70%;
        }

        @include breakpoint-up(xl) {
            background-position: 80% 70%;
        }

        .top-baner__box {
            padding-bottom: 90px;

            @include breakpoint-up(md) {
                padding-bottom: 140px;
            }
        }
    }

    &--alt2 {
        margin: 0;
        padding-left: 25px;
        padding-right: 25px;
        align-items: flex-start;
        padding-top: 68px;
        min-height: 382px;
        background-size: 750px;
        background-position: 4px 120px;

        @include breakpoint-up(md) {
            padding-top: 82px;
            min-height: 576px;
            background-size: 980px;
            background-position: center 171px;
        }

        .visa-card {
            max-width: 310px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}