.visa-baner {
    background-size: 189%;
    background-position: 54% -8%;
    background-repeat: no-repeat;
    padding: 65px 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint-up(sm) {
        background-size: cover;
        background-position: center;
    }

    @include breakpoint-up(lg) {
        background-size: 58%;
        background-position: 50% -1%;
        padding: 80px 25px;
    }

    &__box {
        text-align: center;
    }

    &__title {
        @include breakpoint-up(md) {
            margin-bottom: 15px;
        }
    }

    &__info {
        color: color(secondary);
        font-size: 9px;
        line-height: 11px;
        margin-top: 32px;

        @include breakpoint-up(md) {
            margin-top: 20px;
        }
    }
    
    &--big {
        height: 498px;
        align-items: flex-start;
        background-position: center;
        background-size: 217%;

        @include breakpoint-up(sm) {
            background-size: cover;
            background-position: center;
        }

        @include breakpoint-up(md) {
            height: 600px;
        }

        @include breakpoint-up(lg) {
            background-size: contain;
        }
    }

}