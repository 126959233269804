            .main-menu {
                background-color: color(white);
                position: relative;
                &__container {
                    border-bottom: 1px solid color(primary);
                    margin: 0 25px;
                    padding: 30px 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    @include breakpoint-up(md) {
                        justify-content: center;
                    }
                }
                .main-nav {
                    min-width: 200px;
                    margin: 0;
                    background-color: color(white);
                    position: absolute;
                    left: 0;
                    visibility: hidden;
                    z-index: -1;
                    top: 78px;
                    transition: 0.5s all ease-in-out;
                    opacity: 0;
                    padding: 22px 25px 55px;
                    &.show-menu {
                        left: 0;
                        opacity: 1;
                        visibility: visible;
                        z-index: 1;
                    }
                    &__item {
                        margin-bottom: 20px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        @include breakpoint-up(md) {
                            display: inline-block;
                            margin: 0 20px;
                            &:first-child {
                                margin-left: 0;
                            }
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                    &__link {
                        font-size: 12px;
                        transition: 0.3s all ease-in-out;
                        position: relative;
                        &.c-btn {
                            &:after {
                                display: none;
                            }
                        }
                        &:after {
                            content: '';
                            position: absolute;
                            left: 50%;
                            bottom: -5px;
                            transform: translateX(-50%);
                            width: 0;
                            height: 2px;
                            background-color: color(third);
                            transition: 0.3s all ease-in-out;
                        }
                        &:hover,
                        &.current-menu-item {
                            &::after {
                                width: 100%;
                            }
                        }
                        @include breakpoint-up(md) {
                            font-size: 14px;
                        }
                    }
                    @include breakpoint-up(md) {
                        width: auto;
                        visibility: visible;
                        opacity: 1;
                        z-index: 0;
                        padding: 0;
                        position: static;
                        background-color: transparent;
                    }
                }
            }
            
            .burger {
                cursor: pointer;
                transition: all 0.5s ease-in-out;
                &.active {
                    transform: rotate(90deg);
                }
                @include breakpoint-up(md) {
                    display: none;
                }
                &__stick {
                    width: 20px;
                    background-color: color(black);
                    height: 2px;
                    margin-bottom: 6px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            
            .wpml-ls-legacy-dropdown {
                width: auto;
                a {
                    border: 0;
                    font-size: 14px;
                    line-height: 19px;
                    padding-left: 20px;
                    padding-right: 0 !important;
                    padding-bottom: 0;
                    text-transform: uppercase;
                    &:hover {
                        background: transparent;
                    }
                    &.wpml-ls-item-toggle:after {
                        right: auto;
                        left: 0;
                    }
                }
            }
            
            .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover>a,
            .wpml-ls-legacy-dropdown a:focus,
            .wpml-ls-legacy-dropdown a:hover {
                background: transparent;
            }
            
            .wpml-ls-legacy-dropdown .wpml-ls-sub-menu {
                border-top: 0;
                border-left: 1px solid #bebebe;
                border-right: 1px solid #bebebe;
                border-bottom: 1px solid #bebebe;
                background: white;
                border-radius: 0 0 5px 5px;
                box-shadow: 0px 15px 20px #def4f9
            }
            
            .lang-switcher {
                display: flex;
                position: absolute;
                top: 29px;
                background: #fff;
                right: 10px;
                &:hover {
                    border-top: 1px solid #bebebe;
                    border-left: 1px solid #bebebe;
                    border-right: 1px solid #bebebe;
                    border-radius: 5px 5px 0 0!important;
                    height: 28px!important;
                    box-shadow: 0px 0px 25px #def4f9;
                    .wpml-ls-sub-menu { 
                        visibility: visible;
                        right: -1px;
                        left: -1px;
                    }
                    .dropdown-icon {
                        transform: rotate(180deg);
                        position: absolute;
                        top: 7px;
                        left: 5px;
                        &:hover {
                            .wpml-ls-sub-menu { 
                                visibility: visible;
                            }
                        }
                        a {
                            &:hover {
                                color: #694BFF;
                            }
                        }
                    }
                }
            }
            
            .wpml-ls {
                width: 51px;
                &:hover {
                    .wpml-ls-sub-menu { 
                        visibility: visible;
                    }
                }
            }
            
            .wpml-ls-legacy-dropdown a.wpml-ls-item-toggle:after {
                display: none;
            }
            
            .wpml-ls-legacy-dropdown a {
                padding-right: 10px;
                padding-left: 20px;
                background:transparent!important;
            }
            
            .dropdown-icon {
                position: absolute;
                top: 9px;
                left: 2px;
                z-index: 1;
            }
            
            .wpml-ls-last-item {
                padding: 0 0 5px 0!important;
            }