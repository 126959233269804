.application-steps {
    padding: 50px 0 45px;

    &__top {
        position: relative;
    }

    &__link {
        color: color(third);
        text-decoration: underline;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &__title {
        margin-bottom: 15px;
        max-width: 140px;

        @include breakpoint-up(sm) {
            max-width: 100%;
        }

        @include breakpoint-up(md) {
            margin-bottom: 40px;
            text-align: center;
        }
    }

    @include breakpoint-up(md) {
        padding: 80px 0;
    }

    &__col {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 24px;

        @include breakpoint-up(md) {
            flex: 0 0 360px;
            max-width: 360px;
        }
    }
}

.step-single {
    height: 100%;
    color: color(white);
    background-color: color(dark);
    border-radius: 4px;
    padding: 27px 20px 22px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;

    @include breakpoint-up(md) {
        padding: 28px 30px 15px 28px;
        min-height: 220px;
    }

    &__desc {
        line-height: 20px;
        margin-bottom: 20px;
        padding-right: 28px;

        @include breakpoint-up(md) {
            line-height: 24px;
            padding-right: 12px;
        }
    }

    &__title {
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 0;

        @include breakpoint-up(md) {
            font-size: 48px;
            line-height: 64px;
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__arrow {
        flex: 0 0 105px;
        max-width: 105px;
        height: 18px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        svg {
            path {
                transition: all 0.3s ease-in-out;
                stroke: color(white);
            }
        }

        &::before {
            content: '';
            background-color: color(white);
            position: absolute;
            right: 0;
            top: 50%;
            width: 43px;
            height: 1px;
            transition: all 0.3s ease-in-out;
        }
    }

    &:hover {
        transform: scale(1.05,1.05);

        .step-single__arrow {
            &::before {
                width: 100%;
            }
        }
    }
}

.steps-thx {
    margin-top: 68px;
    margin-bottom: 55px;

    @include breakpoint-up(md) {
        margin-bottom: 60px;
        margin-top: -128px;
    }

    &__box {
        margin-top: 40px;

        @include breakpoint-up(md) {
            margin-top: 62px;
        }
    }

    &__title {
        margin-bottom: 20px;

        @include breakpoint-up(md) {
            margin-bottom: 30px;
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 -15px;
    }

    &__col {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 15px;

        @include breakpoint-up(sm) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    &__content {
        max-width: 494px;
        line-height: 20px;

        p {
            line-height: 20px; 
        }

        @include breakpoint-up(md) {
            line-height: 24px;

            p {
                line-height: 24px; 
            }
        }

        &--right {

            @include breakpoint-up(md) {
                margin-right: 0;
                margin-left: auto;
            }
        }
    }
}