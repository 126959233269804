$breakpoints: (
    mini: 0,
    micro: 360px,
    xs: 480px, 
    sm: 576px, 
    md: 768px, 
    lg: 992px, 
    xl: 1200px,
);

$breakpoints_down: (
    mini: 0,
    micro: 359px,
    xs: 479px, 
    sm: 575px,
    md: 767px,
    lg: 991px,
    xl: 1199px,
    xxl: 1439px,
    fhd: 1559px,
    wide: 1600px,
    fhdplus: 1919px,
);

$container-sizes: (
    sm: 540px, md: 720px, lg: 960px, xl: 1170px 
);

/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin breakpoint-up($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {
        // Prints a media query based on the value
        @media (min-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}
@mixin breakpoint-down($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints_down, $breakpoint) {
        // Prints a media query based on the value
        @media (max-width: map-get($breakpoints_down, $breakpoint)) {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}