input[type='text'].input-custom-date, .datepicker_with_icon {
    &:active, &:focus {
        border-color: color(primary) !important;
        background-image: url('svg/calendar-active.svg');

        &::placeholder {
            color: color(black);
        }
    }

    background-image: url('svg/calendar.svg');
    background-repeat: no-repeat;
    background-size: 22px 18px;
    background-position: 90% center;
}

.qs-controls {
    background-color: color(white);
    justify-content: flex-end;
    margin-bottom: 7px;
    padding: 0 5px;
}

.qs-arrow {
    width: 10px;

    &:hover {
        background-color: transparent;
    }

    &.qs-left:after {
        border-width: 3px 4px 3px 0;
        border-color: transparent #000000 transparent transparent;
    }

    &.qs-right:after {
        border-width: 3px 0 3px 4px;
        border-color: transparent transparent transparent #000000;
    }
}

.qs-month-year {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;

    &:hover {
        text-decoration: none;
    }
}

.qs-datepicker-container {
    width: 189px;
    border-color: color(primary);
    box-shadow: none;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -2px;

    @include breakpoint-up(md) {
        width: 175px;
    }
}

.qs-day {
    font-size: 9px;
    line-height: 11px;
    font-weight: 400;
    color: color(black);
}

.qs-num {
    font-size: 14px;
    line-height: 24px;
    border-radius: 50%;

    &.qs-current {
        color: color(black);
        background-color: transparent !important;
        font-weight: 400;
        text-decoration: none;
    }

    &:hover, &.qs-active {
        color: color(white);
        background-color: color(third) !important;
        text-decoration: none;
    }

    &.qs-disabled {
        color: color(primary);
        opacity: 1;

        &:hover {
            color: color(primary); 
            background-color: transparent !important;
        }
    }
}


input.datepicker {
    background-image: url('svg/calendar.svg');
    background-repeat: no-repeat;
    background-size: 22px 18px;
    background-position: 90% center;

    &:active, &:focus {
        border-color: color(primary) !important;
        background-image: url('svg/calendar-active.svg');

        &::placeholder {
            color: color(black);
        }
    }
}

.gform-theme-datepicker {
    padding: 8px;
    margin-top: -2px;
    margin: 0;
    width: 189px;
    background-color: color(white);
    border: 1px solid color(primary);
    border-color: color(primary);
    box-shadow: none;
    border-top: none;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -2px;
    
    

    @include breakpoint-up(md) {
        width: 175px;
    }
}

.ui-datepicker-header {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ui-datepicker-title {
        order: 1;
        margin: 0 6px;
    }

    .ui-datepicker-prev {
        order: 0;
        display: block;
        width: 10px;
        height: 10px;
        cursor: pointer;

        .ui-icon {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 3px 4px 3px 0;
            border-color: transparent #000000 transparent transparent;
        }

        &.ui-state-disabled {
            .ui-icon {
                display: none;
            }
        }
        
    }

    .ui-datepicker-next {
        order: 2;
        display: block;
        width: 10px;
        height: 10px;
        cursor: pointer;

        .ui-icon {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 3px 0 3px 5px;
            border-color: transparent transparent transparent #000000;
        }

        &.ui-state-disabled {
            .ui-icon {
                display: none;
            }
        }
    }

    .ui-icon {
        display: block;
    }
}

.ui-datepicker-calendar {
    table-layout: fixed;
    margin: 0 auto;

    th {
        font-size: 9px;
        line-height: 11px;
        font-weight: 400;
        color: color(black);
    }

    td {
        padding: 0;
        text-align: center;
        width: 20px;
        height: 20px;
        font-size: 14px;

        &.ui-datepicker-unselectable {
            color: color(primary);
        }

        span, a {
            width: 20px;
            height: 20px;
            display: block;
            border-radius: 50%;
            line-height: 21px;
        }

        a.ui-state-active, a:hover {
            color: color(white);
            background-color: color(third) !important;
            text-decoration: none;
        }
    }
}

.qs-arrow {
    width: 10px;

    &:hover {
        background-color: transparent;
    }

    &.qs-left:after {
        border-width: 3px 4px 3px 0;
        border-color: transparent #000000 transparent transparent;
    }

    &.qs-right:after {
        border-width: 3px 0 3px 4px;
        border-color: transparent transparent transparent #000000;
    }
}