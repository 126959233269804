.c-btn {
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    padding: 3px 17px;
    border: 1px solid color(third);
    border-radius: 47px;
    transition: all 0.3s ease-in-out;

    background: color(third);
    color: color(white);

    @include breakpoint-up(md) {
        padding: 5px 24px;
        font-size: 14px;
        line-height: 24px;
    }

    text-decoration: none;
    cursor: pointer;

    &:active,
    &:focus,
    &:hover {
        background: color(white);
        color: color(third);
    }

    &--alt {
        background: color(white);
        color: color(third);

        &:active,
        &:focus,
        &:hover {
            background: color(third);
            color: color(white);
        }
    }

    &--dsb {
        cursor: default;
        background: color(primary);
        color: color(white);
        border-color: color(primary);

        &:active,
        &:focus,
        &:hover {
            background: color(primary);
            color: color(white);
        }
    }

    &--dsba {
        cursor: default;
        background: color(white);
        color: color(primary);
        border-color: color(primary);

        &:active,
        &:focus,
        &:hover {
            background: color(white);
            color: color(primary);
        }
    }
}
