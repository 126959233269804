.stage {
    &-title {
        position: absolute;
        clip: rect(1px 1px 1px 1px); /* for Internet Explorer */
        clip: rect(1px, 1px, 1px, 1px);
        padding: 0;
        border: 0;
        height: 1px;
        width: 1px;
        overflow: hidden;  
    }

    h2 {
        text-align: center;
        margin-bottom: 50px;
        font-size: 36px;
        line-height: 42px;
        font-weight: 400;

        @include breakpoint-up(md) {
            font-size: 48px;
            line-height: 54px;
            margin-bottom: 67px;
        }
    }

    h3 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        margin-bottom: 24px;

        @include breakpoint-up(md) {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 40px;
        }
    }

    .gfield_required_text {
        display: none;
    }
}

.gform_submission_error {
    display: none;
}

.gform_page_footer {
    text-align: center;
    margin-top: 4px;

    @include breakpoint-up(lg) {
        margin-top: 78px;
    }
}

.gform_previous_button {
    display: none;
}

.gform_next_button {
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    padding: 3px 17px;
    border: 1px solid color(third);
    border-radius: 47px;
    transition: all 0.3s ease-in-out;

    background: color(third);
    color: color(white);

    @include breakpoint-up(md) {
        padding: 5px 24px;
        font-size: 14px;
        line-height: 24px;
    }


    text-decoration: none;
    cursor: pointer;

    &:active,
    &:focus,
    &:hover {
        background: color(white);
        color: color(third);
    }
}

.gf-consent  {
    border: none;
    margin-top: 27px;
    padding: 0;
    line-height: 20px;

    @include breakpoint-up(md) {
        line-height: 24px;
    }

    input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ label::after {
            display: block;
        }    
    }

    label {
        display: block;
        position: relative;
        padding-left: 30px;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 20px;
            border: 1px solid color(primary);
            border-radius: 4px;
        }

        &::after {
            content: '';
            position: absolute;
            left: 9px;
            top: 4px;
            width: 4px;
            height: 8px;
            border: solid color(third);
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            display: none;
        }
    }

    .ginput_container {
        display: flex;
    }

    .gfield_label, .gfield_required {
        display: none;
    }

    .validation_message {
        color: color(error);
    }
}

.gf {

    &-container {
        max-width: 100%;
        margin: 0 auto;

        @include breakpoint-up(sm) {
            max-width: 488px;
        }
    }

    &-row {
        margin: 0 -12px;

        @include breakpoint-up(lg) {
            margin: 0 -16px;
            display: flex;
            position: relative;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }

        &::after {
            display: none;
            content: '';
            background-color: color(primary);
            position: absolute;

           @include breakpoint-up(lg) {
            display: block;
            width: 1px;
            height: 100%; 
            left: 50%;
            top: 0;
            transform: translateX(-50%);
           }
           
        }
    }

    &-txt  {
        flex: 0 0 50%;
        max-width: 50%;
    }

    &-checkbox {
        flex: 0 0 45%;
        max-width: 45%;

        @include breakpoint-up(sm) {
            flex: 0 0 49%;
            max-width: 49%;
        }
    }

    &-hidden {
        display: none;
    }

    &-half {
        flex: 0 0 100%;
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;

        @include breakpoint-up(sm) {
            flex: 0 0 424px;
            max-width: 424px;
            margin: 0 auto;
        }

        &--right {
            
            @include breakpoint-up(lg) {
                margin-right: 0;
                margin-left: auto;
            }
        }

        &--left {
            padding-bottom: 40px;
            margin-bottom: 62px;
            position: relative;

            &::after {
                content: '';
                width: calc(100% - 24px);
                height: 1px;
                background-color: color(primary);
                position: absolute;
                left: 12px;
                bottom: 0;

                @include breakpoint-up(sm) {
                    width: calc(100% - 32px);
                    left: 16px;
                }

                @include breakpoint-up(lg) {
                    display: none;
                }
            }

            @include breakpoint-up(lg) {
                padding-bottom: 0;
                margin-bottom: 0;
                margin-right: auto;
                margin-left: 0;
            }
        }

        fieldset {
            border: none;

            .gfield_label {
                margin-bottom: 10px; 

                @include breakpoint-up(md) {
                    margin-bottom: 21px; 
                }
            }

            .gfield_radio {
                display: flex;
                justify-content: space-between;
            }
        }

        .gfield {
            position: relative;
            display: inline-block;
            padding: 0 12px;

            @include breakpoint-up(sm) {
                padding: 0 16px;
            }

            &.gform_hidden {
                padding: 0;
            }            

            &_html  {
                display: block;
                flex: 0 0 100%;
                max-width: 100%;
            }

            &.gform_item_wrapper_end {
                display: none;
            }

            input {
                width: 100%;
            }

            .select2-container {
                display: block;
                width: 100% !important;
            }

            .gf-minititle {
                display: block;

                @include breakpoint-up(md) {
                    margin-bottom: 20px;
                }
            }

            &_error {
                input, .select2-container .select2-selection--single {
                    border-color: color(error);
                }
            } 

            &_validation_message {
                font-size: 9px;
                line-height: 11px;
                color: color(error);
                position: absolute;
                right: 16px;
                top: 3px;
            }

            .gchoice {
                position: relative;
                height: 20px;

                input {
                    position: absolute;
                    opacity: 0;
                }

                label {
                    display: block;
                    padding-left: 30px;
                    padding-right: 10px;
                    line-height: 20px;
                    position: relative;
                    cursor: pointer;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        height: 20px;
                        width: 20px;
                        border: 1px solid color(primary);
                        border-radius: 50%;
                    }

                    &:hover {
                        &::after {
                            display: block;
                            background-color: color(third);
                        }
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        display: none;
                        left: 8px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background-color: transparent;
                    }
                }

                input:checked ~ label::after {
                    background-color: color(third);
                }

                input:checked ~ label::after {
                    display: block;
                }
                  
            }
        }
    }

    &-select {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;

        &--big {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    &-select, &-txt {
        margin-bottom: 28px;
    }

    &-textarea {
        margin-bottom: 32px;

        label {
            line-height: 20px;

            @include breakpoint-up(md) {
                line-height: 24px;
            }
        }

        textarea {
            width: 100%;
            height: 147px;
            @include breakpoint-up(md) {
                height: 116px;
            }
        }

        &--big {
            textarea {
                @include breakpoint-up(md) {
                    height: 232px;
                }
            }
        }
    }

    &_page_steps {
        list-style: none;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin: 0 auto 50px;

        @include breakpoint-up(md) {
            margin-bottom: 73px;
        }
    }

    &_step {
        text-align: center;
        padding: 0 15px;
        position: relative;

        @include breakpoint-up(md) {
            padding: 0 33px;
        }

        &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 10px;
            width: 100%;
            height: 2px;
            background-color: color(primary);
            z-index: -2;
        }

        &_first {
            &::after {
                left: 50%;
            }
        }

        &_last {
            &::after {
                display: none;
            }
        }

        &_completed, &_previous {
            &::after {
                background-color: color(third);
            }

            .gf_step_number {
                background-color: color(third);
            }

            .gf_step_label {
                color: color(third);
            }
        }

        &_number {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 10px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: color(primary);
            color: color(white);

            .gf_step_active & {
                background-color: color(third);
            }
        }

        &_label {
            display: block;
            width: 80px;
            font-size: 9px;
            line-height: 20px;

            @include breakpoint-up(md) {
                font-size: 14px;
                line-height: 24px;
                width: 140px;
            }

            .gf_step_active & {
                color: color(third);
            }
        }
    }
}

.stage {
    margin: 36px 0 68px;

    @include breakpoint-up(md) {
        margin: 36px 0 78px;
    }

    &-nav {
        max-width: 432px;
        margin: 0 auto 50px;

        @include breakpoint-up(md) {
            margin-bottom: 73px;
        }

        &__list {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__item {
            text-align: center;
        }

        &__icon {
            margin: 0 auto 10px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: color(primary);

            &--success {
                background-color: #00E5B6;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    left: 7px;
                    top: 4px;
                    width: 4px;
                    height: 8px;
                    border: solid color(white);
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg); 
                }
            }
        }

        &__title {
            font-size: 9px;
            line-height: 20px;

            @include breakpoint-up(md) {
                font-size: 14px;
                line-height: 24px;
            }

            &--success {
                color: #00E5B6;
            }
        }
    }

    &-title {
        margin-bottom: 49px;

        @include breakpoint-up(md) {
            margin-bottom: 68px;
        }
    }

    &--success {

        @include breakpoint-up(md) {
            margin: 36px 0 131px;
        }

        .success-content {
            max-width: 456px;
        }
    }

    &-subtitle {
        &.success-subtitle {
            text-align: center;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 24px;

            @include breakpoint-up(md) {
                font-size: 24px;
                line-height: 32px;
            }
        }
    }

    &-wrap {
        margin: 0 auto;

        &--success {
            max-width: 456px;
        }

        &__content {
            ol {
                margin: 20px 0;
                list-style: decimal;
                padding-left: 13px;
            }
    
            li {
                margin-bottom: 10px;
            }
    
            a {
                text-decoration: underline;
                transition: 0.3s all ease-in-out;
    
                &:hover {
                    opacity: 0.8;
                }
            }
    
            p {
                font-size: 12px;
                line-height: 20px;
                margin-bottom: 20px;
    
                @include breakpoint-up(md) {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
    }

    &-btns {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
    }
}

.payment {
    &-row {
        flex-direction: column-reverse;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        max-width: 320px;
        margin: 0 auto;

        @include breakpoint-up(lg) {
            flex-direction: row;
            margin-left: auto;
            margin-right: 100px;
            justify-content: flex-end;
            max-width: 626px;
            align-items: flex-start;
        }
    }

    &-col {

        &--big {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 30px;

            @include breakpoint-up(lg) {
                margin-right: 72px;
                flex: 0 0 310px;
                max-width: 310px;
                margin-bottom: 0;  
            }

            .ginput_full {
                margin-bottom: 27px;
            }

            .validation_message {
                margin-top: 10px;
                color: color(error);
            }
        }

        &--small {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 54px;

            @include breakpoint-up(lg) {
                margin-bottom: 0;
                flex: 0 0 244px;
                max-width: 244px;
                padding-left: 23px;
            }
        }
    }

    &-summary {
        @include breakpoint-up(md) {
            margin-top: 52px;
        }

        p {
            font-size: 9px;
            line-height: 11px;
        }

        &__header {
            display: block;
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 22px;
        }

        &__row {
            position: relative;
            display: flex;
            justify-content: space-between;

            &--last {
                margin-bottom: 26px;
                text-decoration: underline;
            }

            p {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 0;
            }
        }

        
    }

    &-title {
        margin-bottom: 24px !important;

        @include breakpoint-up(md) {
            margin-bottom: 20px !important;
        }
    }
}

.tooltip {
    position: absolute;
    left: -25px;
    top: 2px;

    &-icon {
        width: 15px;
        height: 19px;
        background-image: url('svg/tooltip.svg');
        display: block;
        cursor: pointer;
        transition: 0.3s all ease-in-out;

        &:hover {
            background-image: url('svg/tooltip-hover.svg'); 
        }
    }

    &-content {
        visibility: hidden;
        opacity: 0;
        z-index: -1;
        position: absolute;
        left: 0;
        top: -100%;
        transform: translateY(-80%);
        background-color: color(white);
        min-width: 154px;
        max-width: 154px;
        border: 1px solid color(primary);
        border-radius: 4px;
        padding: 15px;
        font-size: 9px;
        line-height: 11px;
        transition: 0.3s all ease-in-out;

        @include breakpoint-up(md) {
            top: 50%;
            left: -8px;
            transform: translate(-100%, -50%);
        }
    }

    &:hover {
        .tooltip-content {
            visibility: visible;
            opacity: 1;
            z-index: 1;
        }
    }
}

.nav-visa {
    display: flex;
    align-items: center;

    &__logo {
        width: 24px;
        height: auto;
        margin-right: 8px;
    }

    &__title {
        margin: 0;
        font-size: 12px;
        line-height: 20px;

        @include breakpoint-up(md) {
            font-size: 14px;
            line-height: 24px;
        }
    }
}

.page-template-page-step, .page-template-page-success {
    .main-menu__container {
        justify-content: center;
    }

    .burger {
        display: none;
    }
}

.page-template-page-step {
    .gfield_label_before_complex {
        display: none;
    }

    .select2-results__options {
        .select2-results__option:first-child {
            color: color(primary);
            cursor: not-allowed;
        }
    }

    .gf_placeholder {
        color: color(primary);
    }

    .select2-container {
        .select2-selection--single .select2-selection__rendered {
            color: color(primary);
        }

        &.changed .select2-selection--single .select2-selection__rendered {
            color: color(black);
        }
    }
}

.ginput_stripe_creditcard {
    input {
        width: 100%;
    }
}
