.section-static {
    margin: 0 auto;
    padding-right: 25px;
    padding-left: 25px;
    max-width: 1158px;
    line-height: 20px;

    @include breakpoint-up(md) {
        line-height: 24px;
    }

    h1 {
        text-align: center;
        font-weight: normal;
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 52px;

        @include breakpoint-up(md) {
            margin-bottom: 73px;
            font-size: 48px;
            line-height: 54px;
        }
    }

    h2,h3 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 25px;

        @include breakpoint-up(md) {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 30px;
        }
    }

    p {
        line-height: 20px;
        margin-bottom: 18px;

        @include breakpoint-up(md) {
            line-height: 24px;
            margin-bottom: 23px;
        }
    }

    &--margins {
        margin: 60px auto 69px;

        @include breakpoint-up(md) {
            margin: 78px auto;
        }
    }
}