.section-title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 25px;

    @include breakpoint-up(md) {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 30px;
    }
}