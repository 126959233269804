.img-txt {
    margin: 52px 0 68px;

    @include breakpoint-up(md) {
        margin: 84px 0 89px;
    }

    &__box {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;

        @include breakpoint-up(sm) {
            flex-direction: row;
        }
    }

    &__btn {
        margin: 16px 0 0;
    }

    &__content {
        flex: 0 0 100%;
        max-width: 100%;
        
        @include breakpoint-up(sm) {
            flex: 0 0 38%;
            max-width: 38%;
            padding-left: 30px;
        }

        &-box {
            max-width: 100%;
            margin: 0;

            @include breakpoint-up(sm) {
                margin: 0 0 0 auto;
                max-width: 310px;
            }
        }
    }

    &__image {
        margin-bottom: 28px;
        flex: 0 0 100%;
        max-width: 100%;

        @include breakpoint-up(sm) {
            flex: 0 0 62%;
            max-width: 62%;
            margin-bottom: 0;
        }

        img {
            max-width: 100%;
            border-radius: 4px;
        }
    }

    &__bottom {
        flex: 0 0 100%;
        max-width: 100%;

        @include breakpoint-up(sm) {
            margin-top: 80px ;
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 -15px;
    }

    &__col {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 15px;

        @include breakpoint-up(sm) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    &__txt {
        @include breakpoint-up(sm) {
            max-width: 494px;
        }

        &--right {

            @include breakpoint-up(md) {
                margin-right: 0;
                margin-left: auto;
            }
        }
    }
}