$font-sizes: ( 
    'title': (xs: 24px, lg: 28px, xl: 32px), 
    'subtitle': (xs: 18px, lg: 22px, xl: 24px), 
    'default': 1rem, 
    'small': .75rem, 
    'tiny': 9px 11px 
);

$fonts: ( 
    'default': 'Neuemontreal',
    'heading': 'Neuemontreal'
);

$font-default: "Neuemontreal", sans-serif;
