
@import '@raa/style-base';
@import 'settings/__settings';
@import 'tools/__tools';

@import 'vendors/__vendors';

@import 'objects/__objects';
@import 'elements/__elements';
@import 'components/__components';
@import 'blocks/__blocks';

@import 'utilities/__utilities';

* {
    outline: none;
    box-sizing: border-box;
}

@include raa-style-base($breakpoints);

@include define-colors($colors);

//define your fonts
@include define-fonts($fonts);
@include define-font-sizes($font-sizes);


//create container and grid
@include create-container('o-container', $container-sizes, 50px);
@include create-grid('o-grid', 12, 30px);


.c-test-block {
    background-color: red;
}