.other-countries {
    margin: 38px 0;

    @include breakpoint-up(md) {
        margin: 50px 0 45px;
    }

    &__title {
        text-align: center;
        margin-bottom: 27px;

        @include breakpoint-up(md) {
            margin-bottom: 33px ; 
        }
    }

    &__row {
        justify-content: flex-start;

        @include breakpoint-up(md) {
            max-width: 720px;
            margin: 0 auto;
        }

        @include breakpoint-up(xl) {
            justify-content: space-between;
            margin: 0 -25px;
            max-width: initial;
        }
    }

    &__col {
        margin-bottom: 15px;
        flex: 0 0 50%;
        max-width: 50%;

        @include breakpoint-up(md) {
            margin-bottom: 20px;
            flex: 0 0 360px;
            max-width: 360px;
        }
    }

    &__header {
        font-size: 9px;
        line-height: 11px;
        color: color(secondary);
        margin-bottom: 15px;
        text-transform: uppercase;

        @include breakpoint-up(md) {
            margin-bottom: 21px;
        }
    }

    &__item {
        margin-bottom: 20px;

        @include breakpoint-up(md) {
            margin-bottom: 15px;
        }
    }

    &__link {
        text-decoration: underline;
        transition: 0.3s all ease-in-out;

        &:hover {
            color: color(third);
        }
    }
}